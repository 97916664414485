<script lang="ts" setup>
import { ref, computed, watch, onMounted, getCurrentInstance } from "vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { getProjectLink, addProjectLink, patchProjectLink, severityCode, getProjectLinkSources } from "@/services/api/iam.api";
import _ from "lodash";
import router from "@/router/index";

const emits = defineEmits(["update-projectlink"]);

const props = defineProps({
  projectId: {
    type: String,
    default: undefined,
  },
  projectLinkId: {
    type: String,
    default: undefined,
  },
  addModus: {
    type: Boolean,
    default: false,
  },
});

const editMode = ref(false);
const selectedData = ref({
  id: undefined,
});

const readOnly = computed(() => !props.addModus && !editMode.value);
const disableSave = computed(() => (!props.addModus && !selectedData.value.id) || isSaving.value);

const dataMustBeRetrieved = ref(false);
const projectLinkSources = ref([]);
const projectLinkSourcesLoaded = ref(false);
const isSaving = ref(false);

watch(
  () => props.projectLinkId,
  () => {
    console.log("ProjectLinkDetail, watch ", props.projectLinkId);
    if (!props.addModus) {
      dataMustBeRetrieved.value = true;
      fetchData();
    }
  },
  { immediate: true }
);

onMounted(() => {
  loadProjectLinkSources();
});

const masterDataLoaded = computed(() => projectLinkSourcesLoaded.value);

watch(
  () => masterDataLoaded.value,
  () => {
    if (masterDataLoaded.value) {
      fetchData();
    }
  },
  { immediate: true }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchData() {
  console.log("ProjectLinkDetail, fetchData ", dataMustBeRetrieved.value, props.projectLinkId, masterDataLoaded.value);
  if (dataMustBeRetrieved.value && masterDataLoaded.value) {
    if (props.projectLinkId && props.projectLinkId !== "") {
      const response = await getProjectLink(props.projectId, props.projectLinkId);
      console.log("ProjectLinkDetail, fetchData response ", response.data);

      if (response && !response.error) {
        selectedData.value = response.data;
      } else {
        proxy.$toaster.error("Ophalen van de data is mislukt! " + response.error);
      }
    }
    dataMustBeRetrieved.value = false;
  }
}

async function loadProjectLinkSources() {
  const response = await getProjectLinkSources();
  if (response && !response.error) {
    projectLinkSources.value = _.sortBy([...response.data.keyDescList], "description");
    projectLinkSourcesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de projectLinkSources is mislukt! " + response.error);
  }
}

async function onEdit() {
  editMode.value = true;
}

async function onCancel() {
  if (props.addModus) {
    router.push({ name: "IAM-ProjectDetails" });
  } else {
    editMode.value = false;
    fetchData();
  }
}

const obs = ref(null);

async function onSave() {
  isSaving.value = true;
  const valid = await obs.value.validate();
  if (valid) {
    let response = undefined;
    if (props.addModus) {
      response = await addProjectLink(props.projectId, selectedData.value);
      console.log("ProjectLink, onSave add ", response);
    } else {
      response = await patchProjectLink(props.projectId, selectedData.value.id, selectedData.value);
      console.log("ProjectLink, onSave patch ", response);
    }
    switch (response.severity) {
      case severityCode.hint:
      case severityCode.warning:
        proxy.$toaster.warning(response.message);
        break;
      case severityCode.error:
        proxy.$toaster.error("Bewaren van data is mislukt! " + response.error);
        break;
    }
    if (response.severity <= severityCode.warning) {
      if (props.addModus) {
        router.push({ name: "IAM-ProjectLinksOverview" });
      } else {
        editMode.value = false;
        emits("update-projectlink");
      }
    }
  }
  isSaving.value = false;
}
</script>

<template>
  <v-container fluid pa-0 class="app-iam">
    <ValidationObserver ref="obs">
      <v-form>
        <v-container fluid pa-0>
          <v-row>
            <v-col>
              <ValidationProvider name="Type" rules="required" v-slot="{ errors }">
                <v-select
                  hide-details="auto"
                  :outlined="!readOnly"
                  :class="{ noBorders: readOnly }"
                  persistent-placeholder
                  label="Type"
                  :items="projectLinkSources"
                  item-text="description"
                  item-value="key"
                  v-model="selectedData.source"
                  :error-messages="errors"
                  :readonly="readOnly"
                ></v-select>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <ValidationProvider name="Referentie" rules="required" v-slot="{ errors }">
                <v-text-field
                  hide-details="auto"
                  label="Referentie"
                  persistent-placeholder
                  :outlined="!readOnly"
                  :class="{ noBorders: readOnly }"
                  v-model="selectedData.sourceReference"
                  :error-messages="errors"
                  :readonly="readOnly"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                hide-details="auto"
                label="Omschrijving"
                :outlined="!readOnly"
                :class="{ noBorders: readOnly }"
                persistent-placeholder
                v-model="selectedData.description"
                :readonly="readOnly"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-container fluid pt-6>
          <v-row>
            <v-col cols="auto" class="form-group" v-if="readOnly">
              <v-btn class="primary" v-on:click="onEdit()" :disabled="disableSave">
                <v-icon dark left>mdi-pencil</v-icon>
                Wijzigen
              </v-btn>
            </v-col>
            <v-col cols="auto" class="form-group" v-else>
              <v-btn class="primary" v-on:click="onSave()" :disabled="disableSave">
                <v-icon dark left>mdi-checkbox-marked-circle</v-icon>
                Opslaan
              </v-btn>
            </v-col>
            <v-col cols="auto" class="form-group" v-show="!readOnly">
              <v-btn v-on:click="onCancel()">
                <v-icon dark left>mdi-cancel</v-icon>
                Annuleren
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-container>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>
